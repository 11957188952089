import React, { useEffect, useMemo, useState } from "react";
import Modal, { ModalSection } from "../../components/Modal";
import {
  acceptTransport,
  assignBourseTransport,
  cancelTransport,
  createNotification,
  formatDoc,
  formatTransportDoc,
  Groups,
  Transports,
  updateTransport,
  Users,
} from "../../services/Firebase";
import Palette from "../../styles/Palette";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import Button from "../../components/Button";
import useDataFromRef from "../../hooks/useDataFromRef";
import firebase from "firebase/compat/app";
import { useAuth } from "../../providers/Auth";
import Global from "../../styles/Global";
import Colors from "../../styles/Colors";
import TransportResume from "../../components/TransportResume";
import ComplementResume from "../../components/Previews/ComplementPreview";
import GoogleMapReact from "google-map-react";
import TransportStatusTag from "../../components/TransportStatusTag";
import DriverPreview from "../../components/Previews/DriverPreview";
import PatientPreview from "../../components/Previews/PatientPreview";
import { toast } from "react-hot-toast";
import DocumentsPreview from "@components/Previews/DocumentsPreview";

function AssignBourseModal({ transport, setViewState }) {
  const [loading] = useState(false);
  const [selected, setSelected] = useState();
  const history = useHistory();

  const { data: selectedGroup } = useDataFromRef({
    ref: selected && Groups.doc(selected),
    listener: true,
    initialState: null,
    simpleRef: true,
    condition: !!selected,
    refreshArray: [selected],
  });

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [type, setType] = useState("ALL");
  const { groups, user } = useAuth();

  const handleAssignBourse = async () => {
    try {
      await assignBourseTransport(transport, selected, selectedGroup);

      toast.success(
        `Transport attribué au groupe de bourse ${
          selectedGroup?.name ? selectedGroup.name : "Tous"
        }`
      );
      setViewState("VIEW");
      history.goBack();
    } catch (error) {
      console.error("Erreur lors de l'attribution à la bourse:", error);
    }
  };

  // const assignBourse = async () => {
  //   if (transport.returnTransportId) {
  //     // TODO : Move ths to Firebase.js
  //     if (selected === "PUBLIC") {
  //       await updateTransport(transport.id, {
  //         regulatedBy: "BOURSE",
  //         bourseType: "PUBLIC",
  //         driver: null,
  //         driverStatus: null,
  //         assignedTo: null,
  //         // bourseGroup: selected,
  //         status: "PENDING",
  //       });
  //       createNotification(
  //         transport,
  //         "TRANSPORT_BOURSE",
  //         "REGULATOR & HOSPITAL"
  //       );
  //       await updateTransport(transport.returnTransportId, {
  //         regulatedBy: "BOURSE",
  //         bourseType: "PUBLIC",
  //         driver: null,
  //         driverStatus: null,
  //         assignedTo: null,
  //         // bourseGroup: selected,
  //         status: "PENDING",
  //       });
  //       // TODO : look for the return transport and update it
  //       // createNotification(transport, "TRANSPORT_ACCEPTED", "REGULATOR & HOSPITAL");
  //     } else {
  //       await updateTransport(transport.id, {
  //         regulatedBy: "BOURSE",
  //         bourseType: "PRIVATE",
  //         driver: null,
  //         driverStatus: null,
  //         assignedTo: null,
  //         bourseGroup: selected,
  //         status: "PENDING",
  //       });
  //       createNotification(
  //         transport,
  //         "TRANSPORT_BOURSE",
  //         "REGULATOR & HOSPITAL"
  //       );
  //       await updateTransport(transport.returnTransportId, {
  //         regulatedBy: "BOURSE",
  //         bourseType: "PRIVATE",
  //         driver: null,
  //         driverStatus: null,
  //         assignedTo: null,
  //         bourseGroup: selected,
  //         status: "PENDING",
  //       });
  //     }
  //   } else {
  //     if (selected === "PUBLIC") {
  //       await updateTransport(transport.id, {
  //         regulatedBy: "BOURSE",
  //         bourseType: "PUBLIC",
  //         driver: null,
  //         driverStatus: null,
  //         assignedTo: null,
  //         // bourseGroup: selected,
  //         status: "PENDING",
  //       });
  //       await createNotification(
  //         transport,
  //         "TRANSPORT_BOURSE",
  //         "REGULATOR & HOSPITAL"
  //       );
  //     } else {
  //       await updateTransport(transport.id, {
  //         regulatedBy: "BOURSE",
  //         bourseType: "PRIVATE",
  //         driver: null,
  //         driverStatus: null,
  //         assignedTo: null,
  //         bourseGroup: selected,
  //         status: "PENDING",
  //       });
  //       await createNotification(
  //         transport,
  //         "TRANSPORT_BOURSE",
  //         "REGULATOR & HOSPITAL"
  //       );
  //     }
  //   }
  //   setViewState("VIEW");
  //   history.goBack();
  //   toast.success(
  //     `Transport attribué au groupe de bourse ${
  //       selectedGroup?.name ? selectedGroup.name : "Tous"
  //     }`
  //   );
  // };

  console.log("selected", selected);

  useEffect(() => {
    if (selectedGroup) {
      const membersPromise = selectedGroup?.members.map(
        (memberId) =>
          new Promise((resolve, reject) => {
            Users.doc(memberId)
              .get()
              .then((m) => {
                resolve({
                  id: m.id,
                  name: m.get("firstName") || m.get("name"),
                  mail: m.get("mail"),
                  role: m.get("role"),
                });
              })
              .catch(reject);
          })
      );
      Promise.all(membersPromise).then(setSelectedMembers);
    } else {
      setSelectedMembers([]);
    }
  }, [selectedGroup]);

  return (
    <Modal
      onPrevClick={() => setViewState("VIEW")}
      onClose={() => setViewState("VIEW")}
      title={`Proposer le transport ${transport?.id?.slice(0, 5)} en bourse`}
    >
      <div className={"container"} style={{ height: 500 }}>
        <div
          className={"containerRow"}
          style={{
            height: 30,
            width: 350,
            fontWeight: 600,
            alignSelf: "center",
            marginBottom: 20,
          }}
        >
          {[
            { name: "Tous", value: "ALL" },
            { name: "Mes groupes", value: "OWNED" },
          ].map((s) => {
            const isSelected = s.value === type;
            return (
              <div
                key={s.value}
                onClick={() => {
                  setType(s.value);
                }}
                className={"flex"}
                style={{
                  cursor: "pointer",
                  color: isSelected ? Palette.blue : Colors.text.secondary,
                  borderBottom: `${isSelected ? 4 : 2}px solid ${
                    isSelected ? Palette.blue : Palette.lightGrey
                  }`,
                  textAlign: "center",
                }}
              >
                {s.name}
              </div>
            );
          })}
        </div>
        <div className={"flex containerRow"}>
          <div className={"container"} style={{ flex: 2, overflowY: "auto" }}>
            {
              // i want to put the TOUS Group who is a PUBLIC group in the header of the list
            }
            <div key={"PUBLIC"}>
              <div
                onClick={() => setSelected("PUBLIC")}
                className={"containerRow"}
                style={{
                  cursor: "pointer",
                  height: 50,
                  alignItems: "center",
                  padding: 10,
                  boxSizing: "border-box",
                  borderRadius: 10,
                  backgroundColor:
                    selected === "PUBLIC"
                      ? Palette.blueTranslucent
                      : "transparent",
                }}
              >
                <div
                  style={{
                    height: 32,
                    width: 32,
                    borderRadius: 16,
                    backgroundColor: Palette.yellow,
                    marginRight: 10,
                  }}
                />
                <div style={{ fontWeight: 500 }}>Tous</div>
              </div>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  height: 1,
                  backgroundColor: Palette.grey,
                }}
              />
            </div>
            <div className={"container"} style={{ flex: 2, overflowY: "auto" }}>
              {groups
                .filter((g) =>
                  type === "OWNED" ? g.createdBy === user.id : true
                )
                .map((g) => {
                  const isSelected = g.id === selected;
                  return (
                    <div key={g.id}>
                      <div
                        onClick={() => setSelected(g.id)}
                        className={"containerRow"}
                        style={{
                          cursor: "pointer",
                          height: 50,
                          alignItems: "center",
                          padding: 10,
                          boxSizing: "border-box",
                          borderRadius: 10,
                          backgroundColor: isSelected
                            ? Palette.blueTranslucent
                            : "transparent",
                        }}
                      >
                        <div
                          style={{
                            height: 32,
                            width: 32,
                            borderRadius: 16,
                            backgroundColor: g.color,
                            marginRight: 10,
                          }}
                        />
                        <div style={{ fontWeight: 500 }}>{g.name}</div>
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                          height: 1,
                          backgroundColor: Palette.grey,
                        }}
                      />
                    </div>
                  );
                })}
            </div>
            {selected && (
              <div
                className={"container"}
                style={{
                  flex: 1.5,
                  marginLeft: 10,
                  borderLeft: `1px solid ${Palette.grey}`,
                }}
              >
                <div
                  style={{
                    overflowY: "auto",
                  }}
                  className={"flex container"}
                >
                  {selectedGroup &&
                    selectedMembers?.map((m) => {
                      return (
                        <div key={m.id}>
                          <div
                            className={"containerRow"}
                            style={{
                              boxSizing: "border-box",
                              height: 64,
                              alignItems: "center",
                              justifyContent: "space-between",
                              paddingLeft: 20,
                              fontWeight: 500,
                            }}
                          >
                            <div>
                              <div>{m.name}</div>
                              <div
                                style={{
                                  fontSize: 13,
                                  color: Palette.darkGrey,
                                }}
                              >
                                {m.role === "REGULATOR"
                                  ? "Société de transport"
                                  : "Hôpital"}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              marginTop: 10,
                              marginBottom: 10,
                              height: 1,
                              backgroundColor: Palette.grey,
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
                <div className={"containerRow"} style={{ paddingLeft: 30 }}>
                  <Button
                    fullWidth
                    onClick={() => {
                      console.log(selected);
                      handleAssignBourse(selected);
                    }}
                    text={"Proposer à ce groupe"}
                    containerStyle={{ marginRight: 10 }}
                  />
                </div>
              </div>
            )}
          </div>
          {loading && <Loading absolute />}
        </div>
      </div>
    </Modal>
  );
}

const ReturnTransport = ({ transportId, containerStyle }) => {
  const history = useHistory();
  const uid = useAuth().user.uid;
  const { data: transport, loading } = useDataFromRef({
    initialState: null,
    ref: transportId && Transports.doc(transportId),
    simpleRef: true,
    format: formatTransportDoc,
    listener: true,
    condition: transportId,
    refreshArray: [transportId],
  });

  return (
    <div
      style={{
        ...Global.box,
        ...containerStyle,
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <div className={"containerRowSpaceBetween"}>
          <div className={"container"}>
            <div style={{ fontSize: 15 }}>Transport Retour</div>
            <div style={{ fontSize: 12, color: Palette.red }}>
              {moment(transport?.startDate).format("dddd DD MMMM")}
            </div>
          </div>
          <TransportStatusTag transport={transport} user={uid} />
          <Button
            tooltip={"Voir"}
            onClick={() =>
              history.push({ search: `?transport=${transportId}` })
            }
            variant="secondary"
            size="icon"
            imgLeft={"/icons/view.png"}
            iconStyle={{
              width: 24,
              height: 24,
            }}
            containerStyle={{
              marginTop: -10,
              marginBottom: -10,
              marginRight: -5,
            }}
          />
        </div>
      )}
    </div>
  );
};

const DirectionMarker = ({ name, color = Palette.blue }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      position: "relative",
      width: 15,
      height: 15,
      backgroundColor: color,
      borderRadius: 999,
      border: `2px solid ${Palette.white}`,
      ...Global.bigShadow,
    }}
  >
    <div
      style={{
        fontSize: 11,
        fontWeight: 500,
        bottom: 20,
        position: "absolute",
        borderRadius: 10,
        backgroundColor: Palette.white,
        padding: 5,
        ...Global.bigShadow,
      }}
    >
      {name}
    </div>
  </div>
);

const Direction = ({ containerStyle, transport }) => {
  const { from, to } = transport;
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();

  const fromLocation = from.location;
  const toLocation = to.location;

  async function handleMap() {
    const bounds = new maps.LatLngBounds();
    bounds.extend(
      new maps.LatLng(fromLocation.latitude, fromLocation.longitude)
    );
    bounds.extend(new maps.LatLng(toLocation.latitude, toLocation.longitude));
    map.fitBounds(bounds);
  }

  useEffect(() => {
    if (map && maps && fromLocation && toLocation) {
      handleMap();
    }
  }, [map, maps, fromLocation, toLocation]);

  return (
    <div style={{ ...Global.box, padding: 0, height: 170, ...containerStyle }}>
      {fromLocation && toLocation && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyA_HTIrUbSHVhPQntFwf5V15gv5oy5uqPE",
          }}
          defaultCenter={[from.location.latitude, from.location.longitude]}
          defaultZoom={14}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            setMap(map);
            setMaps(maps);
          }}
        >
          <DirectionMarker
            name={from.name}
            lat={fromLocation.latitude}
            lng={fromLocation.longitude}
          />
          <DirectionMarker
            color={Palette.red}
            name={to.name}
            lat={toLocation.latitude}
            lng={toLocation.longitude}
          />
        </GoogleMapReact>
      )}
    </div>
  );
};

export default function Transport({ location }) {
  const { uid } = firebase.auth().currentUser || {};
  const { user } = useAuth();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const transportId = params.get("transport");
  const isVisible = !!params.get("transport");
  const [assignMode, setAssignMode] = useState(false);
  const [viewState, setViewState] = useState("VIEW");

  const now = moment();
  const currentHour = now.hour();
  const currentMinute = now.minute();

  const { data: transport, loading } = useDataFromRef({
    initialState: null,
    ref: transportId && Transports.doc(transportId),
    simpleRef: true,
    format: formatTransportDoc,
    listener: true,
    condition: transportId,
    refreshArray: [transportId],
  });

  const isMyTransport = useMemo(
    () => transport?.createdBy === uid,
    [transport, uid]
  );
  const isRegulator = useMemo(
    () => user?.role === "REGULATOR" && transport?.regulatedBy === uid,
    [transport, uid]
  );

  const { data: collaborators } = useDataFromRef({
    initialState: [],
    ref:
      uid &&
      Users.where("role", "==", "DRIVER").where("regulatedBy", "==", uid),
    // format: formatDoc,
    listener: true,
    condition: assignMode,
  });

  const { data: regulator } = useDataFromRef({
    initialState: null,
    ref: transport?.regulatedBy && Users.doc(transport.regulatedBy),
    simpleRef: true,
    // format: formatDoc,
    listener: true,
    condition: !!transport?.regulatedBy,
  });

  const isNotEditable = ![
    "ENDED",
    "CANCELED",
    "REFUSED",
    "IN_PROGRESS",
  ].includes(transport?.status);

  function Empty() {
    return (
      <div
        style={{ height: "100%", width: "100%" }}
        className={"containerCenter"}
      >
        <h1>Transport introuvable</h1>
      </div>
    );
  }

  const PatientSection = ({ containerStyle }) => {
    return (
      <div className={"container"} style={{ ...Global.box, ...containerStyle }}>
        <PatientPreview patient={transport.patient} />
      </div>
    );
  };

  const Regulation = ({ containerStyle }) => {
    const { samuNumber, demDate } = transport;
    return (
      <div style={{ ...Global.box, ...containerStyle }}>
        <div style={{ fontSize: 17, fontWeight: 500, marginBottom: 20 }}>
          Régulation
        </div>
        {demDate && (
          <div className={"containerRow"} style={{ fontSize: 15 }}>
            <div style={{ color: Colors.text.secondary, marginRight: 5 }}>
              Heure DEM:
            </div>
            <div>{moment(demDate).format("HH:mm")}</div>
          </div>
        )}
        {/* <div className={"containerRow"} style={{ fontSize: 15 }}> */}
        {/*  <div style={{ color: Colors.text.secondary, marginRight: 5 }}> */}
        {/*    PDA: */}
        {/*  </div> */}
        {/*  <div> */}
        {/*    [DEV] */}
        {/*  </div> */}
        {/* </div> */}
        {samuNumber && (
          <div className={"containerRow"} style={{ fontSize: 15 }}>
            <div style={{ color: Palette.red, marginRight: 5 }}>N° SAMU:</div>
            <div>{samuNumber}</div>
          </div>
        )}
      </div>
    );
  };

  const Transport = ({ containerStyle }) => {
    const { name, address, phoneNumber } = regulator || {};
    return (
      <div style={{ ...Global.box, ...containerStyle }}>
        <div style={{ fontSize: 17, fontWeight: 500, marginBottom: 20 }}>
          Transporteur
        </div>
        {transport?.regulatedBy ? (
          <div className={"container"} style={{ fontSize: 15 }}>
            <div style={{ color: Palette.red }}>{name}</div>
            <div>{address?.address}</div>
            <div>
              {address?.postalCode} {address?.city}
            </div>
            {phoneNumber && (
              <div className={"containerRow"}>
                <div style={{ color: Colors.text.secondary, marginRight: 5 }}>
                  Tel:
                </div>
                <div>{phoneNumber}</div>
              </div>
            )}
          </div>
        ) : (
          <div
            className={"container"}
            style={{ fontSize: 15, color: Palette.red }}
          >
            Pas encore attribué
          </div>
        )}
      </div>
    );
  };

  const DriverSection = () => {
    return (
      <div className={"container"} style={{ ...Global.box, flex: 1 }}>
        <DriverPreview
          {...{ setAssignMode, transport }}
          containerStyle={{ flex: 1 }}
        />
      </div>
    );
  };

  const Assign = () => {
    return (
      <ModalSection
        onClick={() => setAssignMode(false)}
        containerStyle={{
          zIndex: 2,
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          left: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          marginBottom: 0,
          display: "flex",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              ...Global.containerItem,
              height: "calc(100% - 20px)",
              width: 350,
              overflowY: "auto",
              position: "relative",
            }}
            className={"hideScrollbars"}
          >
            <h2 style={{ position: "sticky", marginBottom: 10 }}>
              Collaborateurs disponibles
            </h2>
            {collaborators.map((c, i) => {
              const isLast = i === collaborators.length - 1;
              const isSelected = transport?.assignedTo === c.id;
              return (
                <div
                  onClick={() => {
                    if (transport.goToTransportId) {
                      firebase
                        .firestore()
                        .collection("transports")
                        .doc(transportId)
                        .update({
                          assignedTo: c.id,
                          demDate: moment(transport.demDate)
                            .set({ hour: currentHour, minute: currentMinute })
                            .toDate(),
                          startDate: moment(transport.startDate)
                            .set({ hour: currentHour, minute: currentMinute })
                            .toDate(),
                          regulatedBy: user.id,
                          bourseType: firebase.firestore.FieldValue.delete(),
                          bourseGroup: firebase.firestore.FieldValue.delete(),
                        })
                        .then(() => setAssignMode(false));
                    } else {
                      firebase
                        .firestore()
                        .collection("transports")
                        .doc(transportId)
                        .update({
                          assignedTo: c.id,
                          regulatedBy: user.id,
                          bourseType: firebase.firestore.FieldValue.delete(),
                          bourseGroup: firebase.firestore.FieldValue.delete(),
                        })
                        .then(() => setAssignMode(false));
                    }
                  }}
                  key={c.id}
                  style={{
                    cursor: "pointer",
                    ...Global.containerItem,
                    border: `2px solid ${
                      isSelected ? Palette.blue : Palette.blueTranslucent
                    }`,
                    marginBottom: isLast ? 0 : 5,
                  }}
                >
                  <div style={{ fontWeight: 500 }}>{c.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      </ModalSection>
    );
  };

  const Actions = () => {
    const [isBourseModalVisible, setBourseModalVisible] = useState(false);
    const [isHoursModalVisible, setHoursModalVisible] = useState(false);
    const [endDate, setEndDate] = useState(transport?.endDate);
    const [demDate, setDemDate] = useState(transport?.demDate);
    const [startDate, setStartDate] = useState(transport?.startDate);

    return (
      <div
        className="containerRow"
        style={{
          ...Global.containerItem,
          padding: 10,
          marginLeft: 10,
          position: "absolute",
          right: 0,
          marginRight: 10,
          zIndex: 3,
        }}
      >
        {/* {isMyTransport && */}
        {isNotEditable &&
          (transport?.regulatedBy !== "BOURSE" || user.role === "HOSPITAL") && (
            <div
              className={"containerRow"}
              style={{ padding: 10, marginLeft: 10 }}
            >
              <Button
                tooltip="Modifier"
                onClick={() => {
                  if (isMyTransport) {
                    history.push({ search: `?editTransport=${transportId}` });
                  } else {
                    setHoursModalVisible(true);
                  }
                }}
                variant="secondary"
                size="icon"
                imgLeft={"/icons/edit.png"}
                iconStyle={{
                  width: 20,
                  height: 20,
                }}
                containerStyle={{
                  marginLeft: 5,
                }}
              />
              {/* {isMyTransport && (
                <Button
                  tooltip="Annuler"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Êtes-vous sûr de vouloir annuler ce transport ?"
                      )
                    ) {
                      console.log(
                            "TRANSPORT " +
                              transport.id +
                              " CANCELED_BY_" +
                              user.role
                          );
                      Transports.doc(transportId).update({
                        status: "CANCELED", +"_BY_" +user.role,
                      });
                    }
                  }}
                  variant="secondaryError"
                  size="icon"
                  imgLeft={"/icons/delete.png"}
                  iconStyle={{
                    width: 22,
                    height: 22,
                  }}
                />
              )} */}
            </div>
          )}
        {(isRegulator && transport?.status === "ACCEPTED") ||
        (isMyTransport && isNotEditable) ? (
          <div
            className={"containerRow"}
            style={{
              padding: 10,
            }}
          >
            <Button
              tooltip={"Annuler"}
              // onClick={() => setBourseModalVisible(true)}
              onClick={() => {
                if (
                  transport.createdBy !== user.id ||
                  user.role === "HOSPITAL"
                ) {
                  if (
                    window.confirm(
                      "Êtes-vous sûr de vouloir annuler ce transport ?"
                    )
                  ) {
                    // Transports.doc(transportId).update({
                    //   status: "CANCELLED_BY_" + user.role,
                    // });
                    console.log(
                      "TRANSPORT " + transport.id + " CANCELED_BY_" + user.role
                    );
                    cancelTransport(transport.id, user.role, transport);
                  }
                } else {
                  if (
                    window.confirm(
                      "Êtes-vous sûr de vouloir supprimer ce transport ?"
                    )
                  ) {
                    Transports.doc(transportId).delete();
                  }
                }
                history.goBack();
              }}
              variant="secondaryError"
              size="icon"
              imgLeft={"/icons/cross_red.png"}
              iconStyle={{
                width: 18,
                height: 18,
              }}
              containerStyle={{
                backgroundColor: Palette.redTranslucent,
                marginRight: 5,
              }}
            />

            {user.role !== "HOSPITAL" &&
              (transport.status === "ACCEPTED" ? (
                <Button
                  tooltip="Bourse"
                  onClick={() => {
                    console.log("Attribuer en BOURSE");
                    if (
                      window.confirm(
                        "Êtes-vous sûr de vouloir attribuer ce transport en bourse ?"
                      )
                    ) {
                      console.log("TRANSPORT " + transportId + " BOURSE");
                      setViewState("ASSIGN_BOURSE");
                    }
                  }}
                  variant="secondary"
                  size="icon"
                  imgLeft={"/icons/check_green.png"}
                  iconStyle={{
                    width: 18,
                    height: 18,
                  }}
                  containerStyle={{
                    backgroundColor: Palette.yellowTranslucent,
                    marginRight: 5,
                  }}
                />
              ) : (
                <Button
                  tooltip="Accepter"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Êtes-vous sûr de vouloir accepter ce transport ?"
                      )
                    ) {
                      // Transports.doc(transportId).update({
                      //   // status: "ASSIGNED",
                      //   status: "ACCEPTED",
                      // });
                      acceptTransport(transport.id, { demDate }, transport);
                      if (transport.returnTransportId) {
                        // TODO: NOTIFICIATION FOR RETURN TRANSPORT
                        acceptTransport(
                          transport.returnTransportId,
                          {
                            demDate,
                          },
                          transport
                        );
                        toast.success("Transport A/R accepté.");
                      } else {
                        toast.success("Transport accepté.");
                      }
                      history.goBack();
                    }
                  }}
                  variant="secondarySuccess"
                  size="icon"
                  imgLeft={"/icons/check_green.png"}
                  iconStyle={{
                    width: 18,
                    height: 18,
                  }}
                  containerStyle={{
                    backgroundColor: Palette.greenTranslucent,
                    marginRight: 5,
                  }}
                />
              ))}
          </div>
        ) : null}
        {/* {isRegulator === "ACCEPTED" && transport?.status === "ASSIGNED" && (
          <div
            className={"containerRow"}
            style={{ padding: 10, marginLeft: 10 }}
          >
            <Button
              tooltip="Réguler"
              onClick={() =>
                history.push({
                  search: `?regulatedTransportRequest=${transportId}`,
                })
              }
              variant="secondary"
              size="icon"
              imgLeft={"/icons/edit.png"}
              iconStyle={{
                width: 20,
                height: 20,
              }}
              containerStyle={{
                marginRight: 10,
              }}
            />
            <Button
              tooltip="Annuler"
              onClick={() => {
                setViewState("CANCEL");
              }}
              variant="secondaryError"
              size="icon"
              imgLeft={"/icons/cross_red.png"}
              iconStyle={{
                width: 18,
                height: 18,
              }}
              containerStyle={{
                backgroundColor: Palette.redTranslucent,
              }}
            />
          </div>
        )} */}
        {isBourseModalVisible && (
          <Modal
            style={{
              width: 300,
              height: 175,
            }}
            showPrevButton={false}
            onTitle={() => (
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                <div
                  style={{
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                    width: "100%",
                  }}
                >
                  <div>
                    Quelle action pour Transport
                    <text
                      style={{
                        color: Palette.red,
                      }}
                    >
                      {" " + transportId?.slice(0, 5) + "?"}
                    </text>
                  </div>
                </div>
              </div>
            )}
          >
            <div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
              <div
                style={{
                  padding: 5,
                  backgroundColor: Palette.white,
                  borderRadius: 5,
                  marginHorizontal: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      console.log("Attribuer en BOURSE");
                      if (
                        window.confirm(
                          "Êtes-vous sûr de vouloir attribuer ce transport en bourse ?"
                        )
                      ) {
                        console.log("TRANSPORT " + transportId + " BOURSE");
                        setViewState("ASSIGN_BOURSE");
                      }
                    }}
                    text="Attribuer en BOURSE"
                    variant={"primary"}
                    containerStyle={{
                      marginRight: 5,
                      width: 200,
                      marginBottom: 10,
                    }}
                  />
                  {transport.createdBy === user.id ? (
                    <Button
                      onClick={() => {
                        console.log("Supprimer");
                        if (
                          window.confirm(
                            "Êtes-vous sûr de vouloir supprimer ce transport ?"
                          )
                        ) {
                          console.log("TRANSPORT " + transportId + " DELETED");
                          Transports.doc(transportId).delete();
                          history.push("/planning");
                        }
                      }}
                      text="Supprimer"
                      variant={"primaryError"}
                      containerStyle={{
                        marginRight: 5,
                        width: 200,
                        marginBottom: 10,
                      }}
                    />
                  ) : (
                    <Button
                      onClick={() => {
                        console.log("Annuler");
                        if (
                          window.confirm(
                            "Êtes-vous sûr de vouloir annuler ce transport ?"
                          )
                        ) {
                          console.log(
                            "TRANSPORT " +
                              transport.id +
                              " CANCELED_BY_" +
                              user.role
                          );
                          if (transport.returnTransportId) {
                            // Transports.doc(transportId).update({
                            //   status: "CANCELLED_BY_" + user.role,
                            // });
                            cancelTransport(transport.id, user.role, transport);
                            // Transports.doc(transport.returnTransportId).update({
                            //   status: "CANCELLED_BY_" + user.role,
                            // });
                            cancelTransport(
                              transport.returnTransportId,

                              user.role,
                              transport
                            );
                          } else {
                            // Transports.doc(transportId).update({
                            //   status: "CANCELLED_BY_" + user.role,
                            // });
                            cancelTransport(transport.id, user.role, transport);
                          }
                          history.push("/planning");
                        }
                      }}
                      text="Annuler"
                      variant={"primaryError"}
                      fullWidth
                      containerStyle={{
                        marginRight: 5,
                        width: 200,
                        marginBottom: 10,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Modal>
        )}
        {isHoursModalVisible && (
          //TODO: FINISH THIS
          <Modal style={{ width: 334 }} showPrevButton={false}>
            <TransportResume
              transport={transport}
              editable
              variant={"MODAL"}
              onChange={(startDate) => {
                console.log("startDate", startDate);
                setStartDate(startDate);
              }}
              onChangeDem={(demDate) => {
                console.log("demDate", demDate);
                setDemDate(demDate);
              }}
              onChangeEnd={(endDate) => {
                console.log("endDate", endDate);
                setEndDate(endDate);
              }}
            />
            <Button
              onClick={() => {
                console.log("set HOURS");
                // console.log("startDate", startDate);
                // console.log("demDate", demDate);
                // console.log("endDate", endDate);

                Transports.doc(transportId).update({
                  startDate,
                  demDate,
                  endDate,
                });

                setHoursModalVisible(false);
              }}
              text="Mettre à jour les horaires"
              variant={"primary"}
              containerStyle={{ marginTop: 10 }}
              fullWidth
            />
          </Modal>
        )}
        {/* {isHoursModalVisible && (
          <Modal
            style={{
              width: 300,
              height: 175,
            }}
            showPrevButton={false}
            onTitle={() => (
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                <div
                  style={{
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                    width: "100%",
                  }}
                >
                  <div>
                    Modifier les horaires pour le Transport
                    <text
                      style={{
                        color: Palette.red,
                      }}
                    >
                      {" " + transportId?.slice(0, 5) + "?"}
                    </text>
                  </div>
                </div>
              </div>
            )}
          >
            <div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
              <div
                style={{
                  padding: 5,
                  backgroundColor: Palette.white,
                  borderRadius: 5,
                  marginHorizontal: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      console.log("Attribuer en BOURSE");
                      if (
                        window.confirm(
                          "Êtes-vous sûr de vouloir attribuer ce transport en bourse ?"
                        )
                      ) {
                        console.log("TRANSPORT " + transportId + " BOURSE");
                        setViewState("ASSIGN_BOURSE");
                      }
                    }}
                    text="Attribuer en BOURSE"
                    variant={"primary"}
                    containerStyle={{
                      marginRight: 5,
                      width: 200,
                      marginBottom: 10,
                    }}
                  />
                  {transport.createdBy === user.id ? (
                    <Button
                      onClick={() => {
                        console.log("Supprimer");
                        if (
                          window.confirm(
                            "Êtes-vous sûr de vouloir supprimer ce transport ?"
                          )
                        ) {
                          console.log("TRANSPORT " + transportId + " DELETED");
                          Transports.doc(transportId).delete();
                          history.push("/planning");
                        }
                      }}
                      text="Supprimer"
                      variant={"primaryError"}
                      containerStyle={{
                        marginRight: 5,
                        width: 200,
                        marginBottom: 10,
                      }}
                    />
                  ) : (
                    <Button
                      onClick={() => {
                        console.log("Annuler");
                        if (
                          window.confirm(
                            "Êtes-vous sûr de vouloir annuler ce transport ?"
                          )
                        ) {
                          console.log(
                            "TRANSPORT " +
                              transport.id +
                              " CANCELED_BY_" +
                              user.role
                          );
                          Transports.doc(transportId).update({
                            status: "CANCELLED_BY_" + user.role,
                          });
                          cancelTransport(transport.id, transport, user.role);
                          history.push("/planning");
                        }
                      }}
                      text="Annuler"
                      variant={"primaryError"}
                      fullWidth
                      containerStyle={{
                        marginRight: 5,
                        width: 200,
                        marginBottom: 10,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Modal>
        )} */}
      </div>
    );
  };

  if (!isVisible) return null;
  if (viewState === "VIEW")
    return (
      <Modal
        onTitle={() => (
          <div className={"container"}>
            <div
              className={"containerRow"}
              style={{
                flex: 1,
                fontSize: 20,
                fontWeight: 600,
                alignItems: "center",
              }}
            >
              <div style={{ flex: 1, flexDirection: "row" }}>
                <div>
                  Transport
                  <text style={{ color: Palette.blue, marginLeft: 10 }}>
                    {transportId?.slice(0, 5)}
                  </text>
                </div>

                {user?.role === "REGULATOR" && (
                  <div className={"containerRow"} style={{ fontSize: 13 }}>
                    <div
                      style={{ color: Colors.text.secondary, marginRight: 5 }}
                    >
                      Proposé via
                    </div>
                    <div style={{ fontWeight: 500, color: Palette.red }}>
                      Hostal / Manuel
                    </div>
                  </div>
                )}
              </div>
              <Actions />
            </div>
          </div>
        )}
        // onRight={() => (
        //   <>
        //     {isRegulator && (
        //       <AttributeSelector
        //         transport={transport}
        //         bottom={false}
        //         style={{ height: 42 }}
        //       />
        //     )}
        //   </>
        // )}
      >
        {loading ? (
          <Loading />
        ) : transport ? (
          <div className={"container"}>
            <div className={"containerRow"} style={{ marginBottom: 15 }}>
              <PatientSection containerStyle={{ flex: 1, marginRight: 15 }} />
              {user?.role === "HOSPITAL" ? (
                <Transport containerStyle={{ flex: 1, marginRight: 15 }} />
              ) : (
                <Regulation containerStyle={{ flex: 1, marginRight: 15 }} />
              )}
              <DriverSection />
            </div>
            <div className={"containerRow"}>
              <div className={"container flex"}>
                <TransportResume
                  transport={transport}
                  containerStyle={{ marginRight: 15 }}
                  variant={"MODAL"}
                />
              </div>
              <div className={"container flex"}>
                <ComplementResume
                  transport={transport}
                  containerStyle={{ marginBottom: 15 }}
                />
                <DocumentsPreview
                  transport={transport}
                  containerStyle={{ marginBottom: 15 }}
                />
                <Direction transport={transport} />
                {transport?.returnTransportId && (
                  <ReturnTransport
                    transportId={transport?.returnTransportId}
                    containerStyle={{ marginTop: 15 }}
                  />
                )}
              </div>
            </div>
            {assignMode && <Assign />}
          </div>
        ) : (
          <Empty />
        )}
      </Modal>
    );

  if (viewState === "CANCEL")
    return (
      <Modal
        style={{ width: 334 }}
        showPrevButton={false}
        onTitle={() => (
          <div
            className={"containerCenter"}
            style={{
              fontSize: 15,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            <div>Etes-vous sûr de vouloir annuler</div>
            <div style={{ color: Palette.red }}>
              Transport {transportId.slice(0, 5)}
            </div>
          </div>
        )}
        onFooter={() => (
          <div
            className={"container flex"}
            style={{ justifyContent: "center" }}
          >
            <Button
              onClick={() => {
                // Transports.doc(transportId).update({
                //   status: "CANCELLED_BY_" + user.role,
                // });
                cancelTransport(transport.id, transport, user.role);
                setViewState("VIEW");
              }}
              text={"Annuler"}
              variant={"primaryError"}
              containerStyle={{ height: 40, marginBottom: 10 }}
              fullWidth
            />
            <Button
              onClick={() => setViewState("ASSIGN_BOURSE")}
              text={"Attribuer en bourse"}
              variant={"primary"}
              containerStyle={{ height: 40, marginBottom: 10 }}
              fullWidth
            />
            <Button
              onClick={() => setViewState("VIEW")}
              text={"Retour"}
              variant={"secondary"}
              containerStyle={{ height: 40 }}
              fullWidth
            />
          </div>
        )}
      >
        {!loading && (
          <div
            className={"containerRowCenter"}
            style={{ marginBottom: 20, flex: 1, alignItems: "center" }}
          ></div>
        )}
      </Modal>
    );

  if (viewState === "ASSIGN_BOURSE") {
    return <AssignBourseModal transport={transport} {...{ setViewState }} />;
  }
}
