import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import useDataFromRef from "../../../hooks/useDataFromRef";
import {
  assignTransport,
  formatTransportDoc,
  Transports,
  Users,
} from "../../../services/Firebase";
import firebase from "firebase/compat/app";
import Loading from "../../../components/Loading";
import Global from "../../../styles/Global";
import Palette from "../../../styles/Palette";
import Button from "../../../components/Button";
import { useHistory } from "react-router-dom";
import TransportList from "../../../components/TransportList";
import { toast } from "react-hot-toast";
import moment from "moment";
import NewSegmentedControl from "../../../components/NewSegmentedControl";
import RegulatorTransportCard from "../../../components/RegulatorTransportCard";
import Colors from "../../../styles/Colors";
import TransportStatusTag from "../../../components/TransportStatusTag";
import { STATUS } from "../../../helpers/status";
import DriverPreview from "../../../components/Previews/DriverPreview";
import PatientPreview from "../../../components/Previews/PatientPreview";
import { setGlobal, useGlobal } from "reactn";
import AttributeSelector from "../../../components/AttributeSelector";
import { useAuth } from "../../../providers/Auth";
import LayoutMain from "../../../layouts/main";

const NewMap = ({
  style,
  users,
  transportSelected,
  transportToAssign,
  showAvailableUsers,
  showOnInterventionUsers,
  onAssign,
}) => {
  const history = useHistory();

  const [map, setMap] = useState();
  const [maps, setMaps] = useState();

  function drawItinary({ directionsDisplay, directionsService }) {
    return new Promise((resolve, reject) => {
      directionsDisplay.setMap(map);
      const origin = {
        lat: transportSelected.from.location?.latitude,
        lng: transportSelected.from.location?.longitude,
      };
      const destination = {
        lat: transportSelected.to.location?.latitude,
        lng: transportSelected.to.location?.longitude,
      };

      directionsService.route(
        {
          origin,
          destination,
          travelMode: maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(result);
            resolve();
          } else {
            console.error(`error fetching directions ${result}`);
            reject(new Error("error"));
          }
        }
      );
    });
  }

  async function handleMap({ directionsDisplay, directionsService }) {
    const bounds = new maps.LatLngBounds();
    if (transportSelected) {
      await drawItinary({ directionsDisplay, directionsService });
      bounds.extend(
        new maps.LatLng(
          transportSelected.from.location?.latitude,
          transportSelected.from.location.longitude
        )
      );
      bounds.extend(
        new maps.LatLng(
          transportSelected.to.location?.latitude,
          transportSelected.to.location?.longitude
        )
      );
    }
    users.forEach((user) => {
      if (showAvailableUsers && user.interventionOn) return null;
      bounds.extend(
        new maps.LatLng(user.location?.latitude, user.location?.longitude)
      );
      return null;
    });
    map.fitBounds(bounds);
  }

  useEffect(() => {
    if (map && maps && users?.length > 0) {
      const directionsService = new maps.DirectionsService();
      const directionsDisplay = new maps.DirectionsRenderer({
        polylineOptions: {
          strokeWeight: 6,
          strokeColor: Palette.blue,
          zIndex: 99,
        },
      });
      handleMap({ directionsDisplay, directionsService });
      return () => directionsDisplay.setMap(null);
    }
  }, [map, maps, users, transportSelected, showAvailableUsers]);

  const mapOptions = {
    fullscreenControl: false,
    zoomControl: false,
  };

  return (
    <GoogleMapReact
      style={style}
      bootstrapURLKeys={{
        key: "AIzaSyA_HTIrUbSHVhPQntFwf5V15gv5oy5uqPE",
      }}
      defaultCenter={[46.71109, 1.7191036]}
      defaultZoom={7}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        setMap(map);
        setMaps(maps);
      }}
      options={mapOptions}
    >
      {users.map((u) => {
        return (
          <div
            onClick={() => showAvailableUsers && onAssign(transportToAssign, u)}
            style={{
              position: "relative",
              zIndex: !u.interventionOn ? 1 : 0,
              cursor: showAvailableUsers && "pointer",
            }}
            key={u.id}
            lat={u.location?.latitude}
            lng={u.location?.longitude}
          >
            <img
              src={"/icons/carHorizontal.png"}
              width={62.5}
              height={32}
              style={{
                position: "absolute",
                left: -31.25,
              }}
              alt={u.name}
            />
            {!u.interventionOn && showAvailableUsers && (
              <div
                style={{
                  backgroundColor: "white",
                  position: "absolute",
                  bottom: 10,
                  left: -100,
                  right: -100,
                  borderRadius: 5,
                  padding: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  ...Global.bigShadow,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ fontSize: 16, fontWeight: 500 }}>{u.name}</div>
                </div>
              </div>
            )}
            {u.interventionOn && showOnInterventionUsers && (
              <div
                style={{
                  backgroundColor: "white",
                  position: "absolute",
                  bottom: 10,
                  left: -100,
                  right: -100,
                  borderRadius: 5,
                  padding: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  ...Global.bigShadow,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ fontSize: 16, fontWeight: 500 }}>{u.name}</div>
                  <div style={{ fontSize: 14, color: Palette.darkGrey }}>
                    En intervention
                  </div>
                </div>
                <Button
                  onClick={() =>
                    history.push({
                      search: `?transport=${u.interventionOn}`,
                    })
                  }
                  variant="secondaryBorder"
                  size="icon"
                  imgLeft={"/icons/chevronLeft.png"}
                  iconStyle={{
                    width: 24,
                    height: 24,
                    transform: "rotate(180deg)",
                  }}
                  containerStyle={{
                    width: 40,
                    height: 40,
                  }}
                />
              </div>
            )}
          </div>
        );
      })}

      <div
        style={{
          position: showAvailableUsers ? "absolute" : "relative",
          zIndex: 0,
          top: "-100%",
          left: "-100%",
          bottom: 0,
          right: 0,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        }}
      />
    </GoogleMapReact>
  );
};

const Place = ({ place }) => {
  return (
    <div
      className={"container flex"}
      style={{ fontSize: 12, justifyContent: "space-between" }}
    >
      <div style={{ fontSize: 17, marginBottom: 5, fontWeight: 500 }}>
        {place.name}
      </div>
      <div>
        {place.address} - {place.postalCode} {place.city}
      </div>
      {place.phoneNumber && <div>Tel : {place.phoneNumber}</div>}
      <div className={"containerRowSpaceBetween"}>
        {[
          place.service,
          place.establishment,
          place.chamber,
          place.complement,
          place.moreNotes,
        ].map((v, i) => {
          if (!v) return null;
          return (
            <div style={{ color: Palette.red }} key={i}>
              {v}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Informations = ({ transport, user }) => {
  const history = useHistory();
  const { data: returnTransport } = useDataFromRef({
    ref:
      !!transport.returnTransportId &&
      Transports.doc(transport.returnTransportId),
    initialState: null,
    simpleRef: true,
    listener: true,
    refreshArray: [transport.returnTransportId],
    condition: !!transport.returnTransportId,
    format: formatTransportDoc,
  });
  return (
    <div className={"containerRow flex"}>
      <div
        className={"container flex"}
        style={{
          fontSize: 12,
          justifyContent: "space-between",
          paddingRight: 10,
          boxSizing: "border-box",
        }}
      >
        <div style={{ fontSize: 17, marginBottom: 5, fontWeight: 500 }}>
          Informations
        </div>
        <div style={{ fontSize: 12 }}>{transport.moreNotes}</div>
      </div>
      {returnTransport && (
        <div
          onClick={() =>
            history.push({
              search: `?transport=${transport.returnTransportId}`,
            })
          }
          className={"container"}
          style={{
            width: 200,
            backgroundColor: Palette.blueTranslucent,
            margin: -10,
            padding: 10,
            marginLeft: 0,
            boxSizing: "border-box",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 12,
            cursor: "pointer",
          }}
        >
          <div
            style={{
              fontSize: 17,
              marginBottom: 5,
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            Transport Retour
          </div>
          <div style={{ color: Palette.red }}>
            {moment(returnTransport.startDate).format("dddd DD MMMM")}
          </div>
          <TransportStatusTag transport={returnTransport} user={user} />
        </div>
      )}
    </div>
  );
};

const TransportView = ({ transport, user, style }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  if (!transport) return null;

  return (
    <div
      className={"containerRow"}
      style={{
        position: "absolute",
        left: 20,
        right: 20,
        bottom: 20,
        height: 180,
        ...style,
      }}
    >
      <div className={"container"} style={{ flex: 2, marginRight: 20 }}>
        <div
          className={"containerRow"}
          style={{ marginBottom: 10, height: 50, fontSize: 14 }}
        >
          {transport.demDate && (
            <div
              className={"container"}
              style={{
                ...styles.box,
                marginRight: 20,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>
                <b>DEM :&nbsp;</b>
                {moment(transport.demDate).format("HH:mm")}
              </span>
            </div>
          )}
          <div
            className={"containerRow"}
            style={{
              ...styles.box,
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className={"flex containerRowCenter"}>
              <b>PEC&nbsp;</b>
              <b style={{ color: Palette.red }}>
                {moment(transport.startDate).format("HH:mm")}
              </b>
            </div>
            <img
              src={"/icons/arrow.png"}
              style={{
                marginLeft: 10,
                marginRight: 10,
                width: 12,
                height: 14,
                transform: "rotate(90deg)",
              }}
            />
            <div className={"flex containerRowCenter"}>
              <b style={{ color: Palette.red }}>
                {moment(transport.endDate).format("HH:mm")}
              </b>
              <b>&nbsp;RDV</b>
            </div>
          </div>
          {transport.samuNumber && (
            <div style={{ ...styles.box, marginLeft: 20 }} />
          )}
        </div>
        <div className={"containerRow flex"} style={styles.box}>
          <div
            className={"container"}
            style={{
              boxSizing: "border-box",
              margin: -10,
              marginRight: 10,
              width: 69,
              padding: 10,
              borderRight: `1px solid ${Colors.input.border}`,
              fontSize: 11,
              fontWeight: 700,
              justifyContent: "space-evenly",
              alignItems: "center",
              backgroundColor: Palette.blueTranslucent,
            }}
          >
            {["Départ", "Arrivée", "Autres"].map((v, i) => {
              const isSelected = i === selectedTab;
              return (
                <div
                  key={i}
                  style={{ cursor: "pointer", opacity: isSelected ? 1 : 0.5 }}
                  onClick={() => setSelectedTab(i)}
                >
                  {v}
                </div>
              );
            })}
          </div>
          {selectedTab === 0 && <Place place={transport.from} />}
          {selectedTab === 1 && <Place place={transport.to} />}
          {selectedTab === 2 && (
            <Informations transport={transport} user={user} />
          )}
        </div>
      </div>
      <div className={"container"} style={{ marginRight: 20, minWidth: 200 }}>
        <div
          className={"container"}
          style={{
            ...styles.box,
            marginBottom: 10,
            height: 50,
            justifyContent: "center",
            fontSize: 14,
          }}
        >
          <span>
            <b>STATUT :&nbsp;</b>
            {STATUS[transport.status].name?.toUpperCase()}
          </span>
        </div>
        <div className={"container flex"} style={styles.box}>
          <DriverPreview {...{ transport }} />
        </div>
      </div>
      <div className={"container"} style={{ flex: 1 }}>
        <AttributeSelector
          transport={transport}
          containerStyle={{ marginBottom: 10 }}
        />
        <div className={"container flex"} style={styles.box}>
          <PatientPreview patient={transport.patient} />
        </div>
      </div>
    </div>
  );
};

export default () => {
  const { uid } = firebase.auth().currentUser;
  const { groups } = useAuth();
  const [transportSelected, setTransportSelected] =
    useGlobal("transportSelected");
  const [transportToAssign, setTransportToAssign] =
    useGlobal("transportToAssign");
  const history = useHistory();

  const [type, setType] = useState(0);
  const { data: users, loading } = useDataFromRef({
    ref: Users.where("role", "==", "DRIVER")
      .where("regulatedBy", "==", uid)
      .where("location", "!=", null),
    // format: formatDoc,
    listener: true,
  });

  const yesterday = moment().subtract(1, "days").startOf("day").toDate();
  const today = moment().endOf("day").toDate();

  const { data: transports } = useDataFromRef({
    ref: Transports.where("regulatedBy", "==", uid)
      .where("startDate", ">=", yesterday)
      .where("startDate", "<=", today)
      .orderBy("startDate", "asc"),
    format: formatTransportDoc,
    listener: true,
  });

  // eslint-disable-next-line no-unused-vars
  const { data: bourseTransports } = useDataFromRef({
    ref: Transports.where("regulatedBy", "==", "BOURSE")
      .where("bourseType", "==", "PUBLIC")
      .where("startDate", ">=", moment().toDate())
      .orderBy("startDate", "asc"),
    format: formatTransportDoc,
    listener: true,
  });
  const { data: boursePrivateTransports } = useDataFromRef({
    ref:
      (groups || []).length > 0 &&
      Transports.where("regulatedBy", "==", "BOURSE")
        .where("bourseType", "==", "PRIVATE")
        .where(
          "bourseGroup",
          "in",
          groups.map((g) => g.id)
        )
        .where("startDate", ">=", moment().toDate())
        .orderBy("startDate", "asc"),
    format: formatTransportDoc,
    listener: true,
    condition: (groups || []).length > 0,
  });

  const transportLists = [
    {
      text: "Tous",
      // data: transports.filter((t) => t.status === "PENDING"),
      data: transports.filter(
        (t) => t.status !== "REFUSED" && t.status !== "ENDED"
      ),
      color: Palette.blue,
    },
    {
      text: "En attente",
      // data: transports.filter((t) => t.status === "PENDING" && !t.assignedTo),
      // t.status === Pending or accepted
      data: transports.filter(
        (t) =>
          (t.status === "PENDING" || t.status === "ACCEPTED") && !t.assignedTo
      ),
      color: Palette.blue,
      styles: {
        borderColor: Palette.primary,
        backgroundColor: Palette.secondary,
      },
    },
    {
      text: "Attribué",
      data: transports.filter(
        (t) =>
          !!t.assignedTo &&
          t.status !== "ENDED" &&
          t.status !== "IN_PROGRESS" &&
          t.status !== "REFUSED" &&
          t.status !== "CANCELED"
      ),
      color: Palette.blue,
    },
    // {
    //   text: "Accepté",
    //   data: transports.filter((t) => t.status === "ACCEPTED" && !!t.assignedTo),
    //   color: Palette.green,
    // },

    {
      text: "En cours",
      data: transports.filter(
        (t) =>
          !!t.assignedTo &&
          !!t.driverStatus &&
          t.driverStatus !== "ENDED" &&
          t.status !== "ENDED" &&
          t.status !== "ACCEPTED" &&
          t.status !== "REFUSED" &&
          t.status !== "CANCELED"
      ),
      // (t) => !!t.assignedTo && !!t.driverStatus && t.driverStatus !== "ENDED"
      // data: transports.filter(
      //   (t) =>
      //     t.driverStatus === "RETRIEVING_PATIENT" ||
      //     t.driverStatus === "DEPOSITING_PATIENT"
      // )
      color: Palette.salmon,
      styles: {
        borderColor: Palette.secondary,
        backgroundColor: Palette.secondary,
      },
    },
    {
      text: "Bourse",
      // data: [...boursePrivateTransports, ...bourseTransports],
      // data filter by status pending or accepted
      data: [
        ...boursePrivateTransports.filter(
          (t) => t.status === "PENDING" || t.status === "ACCEPTED"
        ),
        ...bourseTransports.filter(
          (t) => t.status === "PENDING" || t.status === "ACCEPTED"
        ),
      ],
      color: Palette.yellow,
    },
    // {
    //   text: "Annulé par l'ambulancier",
    //   data: transports.filter((t) => t.status === "CANCELED" && t.cancelBy === "REGULATOR",), ajouter le status canceled by regulator
    //   color: Palette.red,
    // },
    // {
    //   text: "Annulé par l'hôpital",
    //   data: transports.filter((t) => t.status === "CANCELED",&& t.cancelBy === "HOSPITAL"), ajouter le status canceled by hospital
    //   color: Palette.red,
    // },
    // {
    //   text: "Refusé",
    //   data: transports.filter((t) => t.status === "REFUSED"),
    //   color: Palette.red,
    // },
    // {
    //   text: "Terminé",
    //   data: transports.filter((t) => t.status === "ENDED"),
    //   color: Palette.green,
    // },
  ];

  // const test = useGlobal("transportToCancelOrBourse");
  // const testAssign = useGlobal("transportToAssignAndChangePEC");

  // useEffect(() => {
  //   console.log(" transportToCancelOrBourse", test); // S'exécute chaque fois que `maVariableGlobale` change
  //   console.log(" transportToAssignAndChangePEC", testAssign); // S'exécute chaque fois que `maVariableGlobale` change
  // }, [test, testAssign]);

  const [, setTransportToAssignAndChangePEC] = useGlobal(
    "transportToAssignAndChangePEC"
  );
  const [, setTransportToRefuse] = useGlobal("transportToRefuse");
  const [, setTransportToCancelOrBourse] = useGlobal(
    "transportToCancelOrBourse"
  );

  return (
    <LayoutMain>
      <div
        className={"containerRow"}
        style={{
          height: "calc(100vh - 100px)",
          marginBottom: -20,
        }}
      >
        {/* <div
          className={"flex container"}
          style={{
            position: "relative",
          }}
        > */}
        <NewMap
          {...{ users }}
          showAvailableUsers={!!transportToAssign}
          showOnInterventionUsers={!transportSelected}
          transportSelected={transportSelected}
          transportToAssign={transportToAssign}
          style={{ height: "100vh" }}
          onAssign={async (transport, user) => {
            await assignTransport(
              transport.id,
              {
                demDate: transport.demDate,
                assignedTo: user.id,
              },
              transport,
              user
            );
            setTransportSelected(null);
            setTransportToAssign(null);
            toast.success(`Course acceptée & assignée à ${user.name}`);
          }}
        />
        {/* TODO: ticket de ARILES - [ ] - Dans l’onglet « carte », est-ce que tu peux enlever toutes les informations / bulles qui sont en bas de la carte, j’ai l’impression que ça gène plus qu’autre chose pour le moment. Si tu peux juste les cacher, on verra par la suite si c’est vraiment utile en utilisant le logiciel. */}
        {/* <TransportView
          style={{ height: 150, width: "calc(100% - 54%)" }}
          transport={transportSelected}
          user={uid}
        /> */}
        {/* </div> */}

        <div
          className={"container"}
          style={{
            position: "absolute",
            top: 100,
            bottom: 0,
            right: 0,
            zIndex: 1,
            margin: 20,
            borderRadius: 10,
            maxWidth: "25%",
            backgroundColor: "white",
            padding: 20,
            boxSizing: "border-box",
            ...Global.bigShadow,
          }}
        >
          <div style={{ marginBottom: 10 }}>
            <div style={{ fontSize: 24, fontWeight: 700, marginBottom: 10 }}>
              Transports
            </div>
            <NewSegmentedControl
              items={transportLists}
              selected={type}
              onSelect={(i) => {
                setType(i);
                console.log(i);
              }}
            />
          </div>
          <div
            style={{ overflowY: "auto" }}
            className={"hideScrollbars container flex"}
          >
            <TransportList
              containerStyle={{ flex: 1 }}
              Component={RegulatorTransportCard}
              isBourse={type === 4}
              transports={transportLists[type]?.data}
              selected={transportSelected}
              setSelected={setTransportSelected}
              onClick={(t) => {
                setTransportSelected(t);
                setTransportToAssign(null);
              }}
              onCancel={() => {
                setTransportSelected(null);
              }}
              onAccept={(t) => setTransportToAssignAndChangePEC(t)}
              onAssign={(t) => setTransportToAssignAndChangePEC(t)}
              onRefuse={(t) => setTransportToRefuse(t)}
              onCancelOrBourse={(t) => {
                setTransportToCancelOrBourse(t);
              }}
            />
          </div>
        </div>

        {loading && <Loading absolute />}
      </div>
    </LayoutMain>
  );
};

const styles = {
  box: {
    ...Global.box,
    backgroundColor: Palette.white,
    padding: 10,
  },
};
