import React from "react";
import Palette from "../styles/Palette";

export default ({ items = [], selected, onSelect, containerStyle }) => {
  // console.log("items", items);
  return (
    <div
      className={"containerRow"}
      style={{
        flex: 1,
        width: "100%",
        backgroundColor: Palette.grey,
        borderRadius: 10,
        height: 50,
        ...containerStyle,
      }}
    >
      {items.map(({ text, data = [], color = Palette.blue }, index) => {
        const isSelected = selected === index;

        return (
          <div
            onClick={() => onSelect(index)}
            className={"containerCenter flex"}
            key={index}
            style={{
              height: "100%",
              backgroundColor: isSelected ? color : undefined,
              fontSize: 14,
              fontWeight: "500",
              width: `${100 / items.length}%`,
              borderRadius: 10,
              color: isSelected ? Palette.white : undefined,
              cursor: "pointer",
              position: "relative",
            }}
          >
            {text}
            {data?.length > 0 && (
              <div
                className={"containerCenter"}
                style={{
                  zIndex: 1,
                  position: "absolute",
                  top: -7.5,
                  right: -7.5,
                  width: 16,
                  height: 16,
                  borderRadius: "50%",
                  backgroundColor: color,
                  border: `2px solid ${Palette.white}`,
                  color: Palette.white,
                  fontSize: 12,
                }}
              >
                {data.length}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
