import React, { useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Button from "./Button";
import Global from "../styles/Global";
import Palette from "../styles/Palette";
import { useAuth } from "../providers/Auth";
import {
  RiCalendarEventFill,
  RiFileListFill,
  RiLayoutLeftFill,
  RiMap2Fill,
  RiTeamFill,
  RiUser2Fill,
  RiBellLine,
  RiCarFill,
} from "react-icons/ri";

export default function TabBar({ gutters = 50, onRight, onNotifyClick }) {
  // const auth = useAuth();
  const { user, signOut, notifications } = useAuth();
  const history = useHistory();

  const routes = useMemo(
    () => [
      {
        route: "/dashboard",
        name: "Tableau de bord",
        Icon: RiLayoutLeftFill,
        condition: user?.role === "HOSPITAL",
      },
      {
        route: "/planning",
        name: "Planning",
        Icon: RiCalendarEventFill,
      },
      {
        route: "/patients",
        name: "Patients",
        Icon: RiUser2Fill,
      },
      {
        route: "/transports",
        name: "Transports",
        Icon: RiFileListFill,
        condition: user?.role === "REGULATOR",
      },
      {
        route: "/map",
        name: "Carte",
        Icon: RiMap2Fill,
        condition: user?.role === "REGULATOR",
      },
      {
        route: "/vehicule",
        name: "Véhicules",
        Icon: RiCarFill,
        condition: user?.role === "REGULATOR",
      },
    ],
    [user?.role]
  );

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        backgroundColor: "#FBFBFB",
        height: 100,
        width: "100%",
        display: "flex",
        alignItems: "center",
        ...Global.shadow,
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          marginLeft: gutters,
          marginRight: gutters,
        }}
      >
        <div style={{ flex: 1 }}>
          <img
            onClick={signOut}
            style={{
              cursor: "pointer",
              height: 64,
            }}
            src="/logoFull.png"
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 3,
          }}
        >
          {routes
            .filter(({ condition = true }) => condition)
            .map(({ route, name, img, Icon }) => {
              // const isLast = i === routes.length - 1
              const match = useRouteMatch({
                path: route,
                exact: false,
              });
              return (
                <div
                  className={"buttonHover"}
                  onClick={() => history.push(route)}
                  key={route}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    fontSize: 14,
                    fontWeight: "500",
                    alignItems: "center",
                    color: match ? Palette.blue : null,
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingBottom: 10,
                    paddingTop: 10,
                    borderRadius: 5,
                  }}
                >
                  {img && (
                    <img
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 8,
                      }}
                      src={match ? `${img.slice(0, -4)}_blue.png` : img}
                    />
                  )}
                  {Icon && (
                    <Icon
                      color={match ? Palette.blue : Palette.black}
                      size={24}
                      style={{ marginRight: 8 }}
                    />
                  )}
                  {name}
                </div>
              );
            })}
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              marginRight: 10,
            }}
          >
            <Button
              onClick={onNotifyClick}
              imgLeft={"icons/icon-notify.png"}
              iconStyle={{ width: 28, height: 28 }}
              variant="secondary"
              containerStyle={{
                background: "none",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 10,
                right: 20,
                pointerEvents: "none",
              }}
            >
              {notifications?.length > 0 && (
                <div
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: 5,
                    backgroundColor: Palette.red,
                    fontSize: 12,
                    color: "white",
                    textAlign: "center",
                    alignContent: "center",
                  }}
                >
                  {notifications?.length}
                </div>
              )}
            </div>
          </div>
          {onRight && onRight()}
          {user?.role != "HOSPITAL" && (
            <Button
              variant={"secondary"}
              onClick={() =>
                history.push({
                  search: `?editGroup=true`,
                })
              }
              containerStyle={{
                marginRight: 10,
              }}
              IconLeft={RiTeamFill}
              text={"Bourse"}
            />
          )}
          <Button
            onClick={() => history.push({ search: `?editTransport=new` })}
            imgLeft={"/icons/plus.png"}
            text={"Nouveau transport"}
          />
        </div>
      </div>
    </div>
  );
}
