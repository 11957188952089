import React from "react";
import Palette from "../styles/Palette";
import moment from "moment";
import TransportCard from "./TransportCard";
import VisibilitySensor from "react-visibility-sensor";
import Button from "./Button";
import { STATUS } from "../helpers/status";
import { useAuth } from "../providers/Auth";

export default function TransportList({
  Component = TransportCard,
  transports = [],
  onClick,
  containerStyle,
  cardStyle,
  selected,
  details = false,
  cardProps,
  onCancel,
  onAccept,
  onAssign,
  onRefuse,
  onCancelOrBourse,
  detectVisibility,
  actualDate,
  setActualDate,
  isBourse = false,
}) {
  const userRole = useAuth().user.role;
  // console.log("USER ROLE: ", userRole);

  return (
    <div
      className={"container flex"}
      style={{
        ...containerStyle,
        paddingBottom: 60,
      }}
    >
      {transports.length > 0 ? (
        <>
          {transports.map((d, i, a) => {
            const prevStartDate = i > 0 ? transports[i - 1].startDate : null;
            const isSameAsPrevDay =
              prevStartDate && moment(prevStartDate).isSame(d.startDate, "d");
            const isLast = i === a.length - 1;
            const isSelected = selected && selected.id === d.id;
            return (
              <div key={d.id} style={{ position: "relative" }}>
                {!isSameAsPrevDay && (
                  <VisibilitySensor
                    offset={{ top: 300 }}
                    partialVisibility
                    onChange={(isVisible) => {
                      if (
                        detectVisibility &&
                        isVisible &&
                        moment(d.startDate)
                          .startOf("day")
                          .isSame(actualDate, "day") === false
                      ) {
                        setActualDate(moment(d.startDate).startOf("day"));
                      }
                    }}
                  >
                    <div
                      id={moment(d.startDate).format("YYYY-MM-DD")}
                      style={{
                        fontSize: 17,
                        fontWeight: 500,
                        marginBottom: 10,
                        marginTop: i !== 0 ? 5 : 0,
                        color: Palette.blue,
                      }}
                    >
                      {moment(d.startDate).format("dddd DD MMMM YYYY")}
                    </div>
                  </VisibilitySensor>
                )}
                <div>
                  <Component
                    {...{ onClick, isBourse }}
                    details={details}
                    transport={d}
                    isReturn={d.isReturn}
                    key={i}
                    selected={[
                      // console.log("IS SELECTED: ", d),
                      isSelected,
                    ]}
                    containerStyle={{
                      ...(isSelected
                        ? {
                            borderColor: Palette.black,
                            // i want to add shadow on the bottom of the card
                            boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.2)",
                            backgroundColor:
                              userRole === "HOSPITAL"
                                ? STATUS[d.status]?.style.backgroundColor
                                : d.regulatedBy === "BOURSE" &&
                                  d.bourseType === "PUBLIC"
                                ? STATUS["BOURSE"]?.style.backgroundColor
                                : d.bourseType === "PRIVATE"
                                ? Palette.purpleTranslucent
                                : STATUS[d.status]?.style.backgroundColor,
                            // backgroundColor:
                            //   STATUS[d.status]?.style.backgroundColor,
                            // userRole === "HOSPITAL"
                            //   ? STATUS[d.status]?.style.backgroundColor
                            //   : d.regulatedBy === "BOURSE"
                            //   ? STATUS["BOURSE"]?.style.backgroundColor
                            //   : STATUS[d.status]?.style.backgroundColor,
                          }
                        : {
                            borderColor:
                              userRole === "HOSPITAL"
                                ? STATUS[d.status]?.style.backgroundColor
                                : d.regulatedBy === "BOURSE" &&
                                  d.bourseType === "PUBLIC"
                                ? STATUS["BOURSE"]?.style.borderColor
                                : d.bourseType === "PRIVATE"
                                ? Palette.purple
                                : STATUS[d.status]?.style.borderColor,
                            backgroundColor:
                              userRole === "HOSPITAL"
                                ? STATUS[d.status]?.style.backgroundColor
                                : d.regulatedBy === "BOURSE" &&
                                  d.bourseType === "PUBLIC"
                                ? STATUS["BOURSE"]?.style.backgroundColor
                                : d.bourseType === "PRIVATE"
                                ? Palette.purpleTranslucent
                                : STATUS[d.status]?.style.backgroundColor,
                          }),
                      marginBottom: isLast ? 0 : 15,
                      ...cardStyle,
                    }}
                    {...cardProps}
                  />
                </div>
              </div>
            );
          })}

          {(selected?.status === "PENDING" ||
            selected?.status === "ACCEPTED") && (
            <div
              className={"container"}
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                padding: 5,
              }}
            >
              <div
                className={"containerRow"}
                style={{
                  zIndex: 1,
                  padding: 5,
                  backgroundColor: Palette.white,
                  borderRadius: 5,
                  position: "relative",
                }}
              >
                {selected.isAssign === false && isBourse !== true ? (
                  <Button
                    onClick={() => onAssign(selected)}
                    fullWidth
                    text="Assigner"
                    containerStyle={{ marginRight: 5 }}
                  />
                ) : selected.isAssign === true &&
                  isBourse !== true &&
                  selected.status !== "IN_PROGRESS" ? (
                  <Button
                    onClick={() => onAssign(selected)}
                    fullWidth
                    text="Réassigner"
                    containerStyle={{ marginRight: 5 }}
                  />
                ) : (
                  // only display if a element is selected
                  selected && (
                    <Button
                      onClick={() => onAccept(selected)}
                      fullWidth
                      text="Accepter"
                      variant={"primary"}
                      containerStyle={{ marginRight: 5 }}
                    />
                  )
                )}
                {selected.regulatedBy !== "BOURSE" ? null : (
                  //TODO: a remettre lors de la V3 (regles de gestion)
                  <>
                    <Button
                      onClick={() => onRefuse(selected)}
                      fullWidth
                      text="Refuser"
                      variant={"primaryError"}
                      containerStyle={{ marginRight: 5 }}
                    />
                    <Button
                      onClick={() => onCancelOrBourse(selected)}
                      fullWidth
                      text="Annuler / Mettre en bourse"
                      variant={"primaryError"}
                      containerStyle={{ marginRight: 5 }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          className={"container flex"}
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ fontWeight: 600, fontSize: 24 }}>Aucun transport</div>
        </div>
      )}
    </div>
  );
}
