import Login from "@pages/auth/Login";
import { ProvideAuth, useAuth } from "@providers/Auth";
import "@styles/App.css";
import "@styles/globals.css";
import moment from "moment";
import "moment/locale/fr";
import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import WebFont from "webfontloader";

import Dashboard from "@pages/main/Hospital/Dashboard";
import EditTransportHospital from "@pages/main/Hospital/EditTransport";
import AssignAndChangePECModal from "@pages/main/Modals/AssignAndChangePECModal";
import CancelOrBourseTransportModal from "@pages/main/Modals/CancelOrBourseTransportModal";
import ChangePECModal from "@pages/main/Modals/ChangePECModal";
import EditGroup from "@pages/main/Modals/EditGroup";
import EditPatient from "@pages/main/Modals/EditPatient";
import RefuseTransportModal from "@pages/main/Modals/RefuseTransportModal";
import Patient from "@pages/main/Patient";
import Patients from "@pages/main/Patients";
import Planning from "@pages/main/Planning";
import EditTransportRegulator from "@pages/main/Regulator/EditTransport";
import Map from "@pages/main/Regulator/Map";
import Transports from "@pages/main/Regulator/Transports";
import Transport from "@pages/main/Transport";
import Vehicules from "@pages/main/Vehicules";
import { Toaster } from "react-hot-toast";
import PrivacyPolicy from "./pages/privacy";

moment.locale("fr");

function EditTransport() {
  const auth = useAuth();
  return auth.user
    ? auth.user.role === "HOSPITAL"
      ? EditTransportHospital()
      : EditTransportRegulator()
    : null;
}

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Inter:400,500,600,700"],
      },
    });
  }, []);

  return (
    <ProvideAuth>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/privacy">
            <PrivacyPolicy />
          </Route>
          <PrivateRoute path="/dashboard">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path="/planning">
            <Planning />
          </PrivateRoute>
          <PrivateRoute path="/patients">
            <Patients />
          </PrivateRoute>
          <PrivateRoute path="/transports">
            <Transports />
          </PrivateRoute>
          <PrivateRoute path="/map">
            <Map />
          </PrivateRoute>
          <PrivateRoute path="/vehicule">
            <Vehicules />
          </PrivateRoute>
        </Switch>
        <PrivateRoute path="/" component={Transport} />
        <PrivateRoute path="/" component={EditTransport} />
        <PrivateRoute path="/" component={Patient} />
        <PrivateRoute path="/" component={EditPatient} />
        <PrivateRoute path="/" component={EditGroup} />
        <PrivateRoute path="/" component={RefuseTransportModal} />
        <PrivateRoute path="/" component={ChangePECModal} />
        <PrivateRoute path="/" component={AssignAndChangePECModal} />
        <PrivateRoute path="/" component={CancelOrBourseTransportModal} />
      </BrowserRouter>
      <Toaster />
    </ProvideAuth>
  );
}

function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
