import React, { useState } from "react";
import "./style.css";
import TabBar from "../../components/TabBar";
import { useMediaQuery } from "react-responsive";
import Colors from "../../styles/Colors";
import Drawer from "../../components/Drawer/Drawer";

export const useGutters = (noPadding) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const calcGutters = isTabletOrMobile ? 50 : 100;
  return !noPadding ? calcGutters : 0;
};

export default function LayoutMain({
  containerStyle = {},
  style,
  children,
  noPadding = false,
  onRight,
}) {
  const gutters = useGutters(noPadding);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <div
      className={"container"}
      style={{
        backgroundColor: Colors.main.background,
        minHeight: "100vh",
        ...containerStyle,
      }}
    >
      <TabBar {...{ onRight }} onNotifyClick={handleOpenDrawer} />
      <Drawer isOpen={isDrawerOpen} onClose={handleCloseDrawer} />

      <div
        style={{
          minHeight: "calc(100vh - 20px)",
          boxSizing: "border-box",
          marginLeft: gutters - 60,
          marginRight: gutters - 60,
          paddingTop: 100,
          marginBottom: 20,
          ...style,
        }}
      >
        {children}
      </div>
    </div>
  );
}
